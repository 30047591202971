import { ActionButton } from "@fluentui/react";
import { Tag } from "@models/Tag";
import { CatalogAPI } from "@utils/APIs/CatalogAPI";
import saveAs from "file-saver";
import React, { useState } from "react";

interface SbomDownloadProps {
  // props
  tag: Tag;
}

export const SbomDownloadButton: React.FC<SbomDownloadProps> = ({ tag }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadSbom = async () => {
    setIsDownloading(true);
    try {
      var sbomData = await CatalogAPI.getArtifactData(
        tag.regHash,
        tag.repository,
        tag.layerZeroDigest
      );
      var sbomName = `${tag.repository}-${tag.name}.spdx.json`;
      if (sbomData) {
        const blob = new Blob([sbomData], { type: "application/spdx+json" });
        saveAs(blob, sbomName);
      } else {
        console.error("Failed to download " + sbomName);
      }
    } catch (error) {
      alert("Error: Failed to download, Sbom not found (404)");
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <ActionButton
      text={isDownloading ? "Downloading SBOM..." : "Download SBOM"}
      title="Download SBOM"
      iconProps={{ iconName: "DownloadDocument" }}
      onClick={downloadSbom}
      disabled={isDownloading}
      styles={{
        root: {
          backgroundColor: "transparent",
          border: "none",
          padding: "0",
          height: "auto",
          minWidth: "unset",
          alignItems: "baseline",
        },
        rootHovered: {
          textDecoration: "underline",
          textDecorationColor: "#0067b8",
        },
        label: {
          color: "#0067b8",
          margin: "0",
          padding: "0",
          paddingTop: "4px",
        },
        icon: {
          color: "#0067b8",
          marginRight: "8px",
          cursor: "pointer",
        },
      }}
    />
  );
};
